.profinder-industry-selector-wrapper .modal {
  position: absolute;
  top: -11rem;
  left: -100%;
  width: 40em;
}

.profinder-industry-selector-wrapper .confirm-button {
  margin-right: 1rem;
}

.profinder-industry-selector-wrapper .select-all-button {
  display: none;
}

.pf-industry-search-box {
  font-weight: 300;
  font-family: "Chivo", sans-serif;
  font-size: 2rem;
  padding: 1em;
  background-color: #fafafa;
  border: 1px solid #ccc;
  width: calc(100% - 4rem);
  margin-bottom: 1rem;
  border-radius: 1rem;
}

.pf-active-filter-button {
  background-color: #ebebeb;
  color: #222;
  border-radius: 5rem;
  padding: .5em 2.5em .5em .75em;
  display: inline-block;
  position: relative;
  margin-right: .25em;
  user-select: none;
  font-weight: 400;
  font-size: 1.0625em;
  margin-bottom: .25em;
  margin-top: .25em;
}
.pf-active-filter-button-delete-container {
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  padding-right: 1rem;
  padding-left: 1rem;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.pf-active-filter-button-delete {
  background-color: #AEAEAE;
  color: #ebebeb;
  font-weight: 700;
  border-radius: 2rem;
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 1;
  user-select: none;
  cursor: pointer;
}
.pf-active-filter-button-delete-container:hover .pf-active-filter-button-delete {
  background-color: #8d8d8d;
}

.pf-active-selections-container {
  min-height: 2.75em;
  border: 1px solid #efefef;
  background-color: #fafafa;
  padding: .375em .5em;
  margin-top: .5em;
  margin-bottom: 1em;
}
.pf-no-search-results {
  font-size: 1.25em;
  text-align: center;
  color: #888;
  padding: 4em 0 8em;
}

.pf-checkbox-container {
  margin-top: 1em;
  margin-bottom: 1em;
}

.pf-options-list-container {
  margin-right: -1.5em;
  max-height: 40vh;
  overflow: auto;
  margin-bottom: 2em;
}

