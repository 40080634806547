.searchResult {
  color: black;
  background: #ffffff;
  border: 1px solid #e1e1e1;
  margin: 1.5rem;
  padding: 1rem 3.4rem;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  animation: fadein 0.5s;
}

.branch {
  opacity: 0.9;
}

.company a:visited,
.company a:link,
.company a:active,
.company a:hover {
  color: #d15371;
}

.branch a:visited,
.branch a:link,
.branch a:active,
.branch a:hover {
  color: #7a65a7;
}

.left {
  flex: 1 0.4;
  padding-right: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.center {
  flex: 1 0.2;
  padding-right: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.name {
  font-family: "Montserrat";
  font-weight: 600;
  font-size: 2.4rem;
  margin-bottom: 0;
}

.region {
  color: #a2a2a2;
  margin-top: 0.9rem;
}

.businessId {
  font-size: 2.4rem;
}

.industry {
  flex: 1 0.5;
  justify-content: flex-end;
  text-align: right;
  font-size: 2.4rem;
  padding-left: 2rem;
}

@media all and (max-width: 480px) {
  .searchResult {
    flex-direction: column;
    padding: 2rem;
  }

  .name {
    font-size: 2.2rem;
  }

  .businessId,
  .industry {
    font-size: 2rem;
    padding-top: 0.5rem;
  }

  .industry {
    text-align: left;
    padding-left: 0;
  }
}
