.mapWrapper {
  position: relative;
  height: 60rem;
}

.mapContainer {
  margin: 0 2rem 2rem;
}

.mapMarkerContainer {
  display: flex;
  justify-content: center;
  margin: 2rem 0 0;
}

.mapMarkerWrapper {
  margin-right: 2rem;
  align-items: center;
  display: flex;
  justify-content: center;
} 

.mapMarkerText {
  color: #212529;
  font-family: Chivo;
  font-weight: 600;
  margin: 0 1rem;
}

.icon {
  height: 4rem;
}
