.table th {
  text-align: left;
  font-family: Montserrat;
  text-transform: uppercase;
}

.headerRow {
  color: #fff;
  padding-bottom: 2rem;
}

.headerRow th {
  background: #ae416a;
}
.table td,
.table th {
  padding: 1rem;
}

.table tbody tr {
  border-bottom: 1px solid #ddd;
}
.table tbody tr:first-of-type td {
  padding-top: 2rem;
}
.table {
  border-collapse: collapse;
  width: 100%;
}