.list-container {
  flex: 1 1 auto;
}

.list {
  padding: 2rem 3rem;
  position: relative;
  animation: fadein 0.5s;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.list > ol {
  margin: 0;
  padding: 0;
}
.row {
  border-bottom: 1px solid #ccc;
  display: flex;
  padding: 1.33rem 0;
  font-size: 1.8rem;
  text-align: left;
  flex: 1;
}

.list td {
  color: #ae416a;
}

.rank {
  font-weight: bold;
  color: #000000;
  width: 3rem;
}

.value {
  padding-left: 1rem;
  text-align: right;
  color: #ae416a;
  flex: 1;
  justify-self: end;
  margin-left: 1rem;
}

.name-cell {
  width: min-content;
}

.name {
  color: #212529;
  padding-left: 0.5rem;
  max-width: 80%;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.list a {
  text-decoration: none;
}

.list a:link,
.list a:visited,
.list a:hover,
.list a:active {
  color:#212529;
}

.list .loading {
  padding-top: 5rem;
}
