.resultScreen {
  padding: 0 1rem 3rem;
  position: relative;
  min-height: 18rem;
}

.resultScreen .backButton {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0 2rem 2rem;
}

.backButton a,
.backButton a:link,
.backButton a:visited,
.backButton a:active,
.backButton a:hover {
  color: #345ca3;
  font-size: 1.8rem;
  text-decoration: underline;
}

.backButton a:focus {
  outline: auto;
}
.searchInfoWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  min-height: 3.2rem;
}

.searchInfo {
  font-size: 3.2rem;
  font-family: "Montserrat";
  font-weight: bold;
  color: #000000;
  margin-left: 2rem;
  animation: fadein 0.5s;
}

.searchInfoResult {
  color: #d15371;
}

/* .excelButton {
  cursor: pointer;
  border: 2px solid #20744b;
  border-radius: 0.8rem;
  color: #20744b;
  background: white;
  display: flex;
  font-size: 1.6rem;
  justify-content: center;
  align-items: center;
  padding-right: 2rem;
  margin-right: 2rem;
}

.excelButton .text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: clip;
}

.excelIcon {
  margin: 1.2rem;
  width: 3rem;
} */

.divider h2 {
  font-family: "Montserrat";
  font-size: 3.2rem;
  font-weight: 600;
  margin-left: 2rem;
  position: relative;
  z-index: 0;
}

.divider h2::before {
  border-top: 1px solid #d15371;
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  bottom: 0;
  width: 35%;
  z-index: -1;
}

.divider h2 span {
  position: relative;
  left: 2rem;
  background: rgb(241, 241, 241);
  padding: 0 1rem;
}

.noResults {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5rem;
}

.usedFilters {
  display: flex;
  flex-direction: row;
  padding: 1rem 1rem 0;
  flex-wrap: wrap;
}

.usedFilters p {
  padding: 1rem 1.5rem;
  margin: 1rem;
  background: #d15371;
  color: white;
  max-width: 40rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.usedFilters .filter {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.pageContent {
  flex: 1 0 auto;
  margin: 1rem auto 3rem;
  width: calc(80% - 2rem);
}

.resultWrapper {
  display: flex;
  align-items: flex-start;
}

.results {
  flex: 0.7666;
}

@media all and (max-width: 736px) {
  .pageContent {
    width: 90%;
  }
}

.usedFilters .filterSeparator {
  margin: 0 1rem;
}

@media all and (max-width: 480px) {
  .resultScreen {
    padding: 3rem 0;
  }

  /*  .excelButton {
    width: 17.5rem;
  } */
}
