.topBar {
  left: 0;
  right: 0;
  height: 5rem;
  background: #28487f;
  color: white;
  display: flex;
  align-items: center;
  position: relative;
}

.topBarContent {
  width: calc(80% - 2rem);
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: auto;
}

.linkLeft {
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
}

.linkRight {
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
}

.topBarContentImage {
  margin-right: 1rem;
  width: 2rem;
}

.topBarContent a:visited,
.topBarContent a:link,
.topBarContent a:hover,
.topBarContent a:active {
  color: white;
  padding: 1.6rem 0;
}

.hero {
  height: 70rem;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background: #3d5d84;  */
  background-position: bottom;
  background-size: cover;
  background-repeat: no-repeat;
  z-index: 1;
  position: relative;
  background-color: #28487f;
}

.imageOverlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(17, 28, 70, 0.35);
}

.headingsContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: -24rem;
  z-index: 2;
  text-decoration: none;
  color: #fff;
  background-color: transparent;
}

.headingsContainer:focus {
  text-decoration: underline;
}
.graphImage {
  height: 14rem;
  width: auto;
  margin-right: 3rem;
  padding-top: 2.5rem;
  display: none;
}

.headings {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.headingTop {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 33px;
  line-height: 40px;
  display: flex;
  align-items: center;
  text-align: center;
  color: white;
  text-shadow: 0px 0px 10px rgba(17, 28, 70, 0.6);
  margin-bottom: -1rem;
}

.headingYritystieto {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 700;
  font-size: 10rem;
  color: white;
  margin: 0;
  text-shadow: 0px 0px 10px rgba(17, 28, 70, 0.6);
  padding-top: 0;
}

.headingBusinessTampere {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 400;
  font-size: 5rem;
  color: white;
  margin: -1rem;
  padding-left: 0.5rem;
  text-shadow: 0px 0px 10px rgba(17, 28, 70, 0.6);
}

.headingBottom {
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: center;
  color: white;
  text-shadow: 0px 0px 10px rgba(17, 28, 70, 0.6);
  width: 55rem;
  margin-top: 2rem;
}

.pageContent {
  flex: 1 0 auto;
  margin: 1rem auto 3rem;
  width: 80%;
}

.problemTip {
  text-align: center;
  border-style: solid;
  border-radius: 5px;
  border-color: red;
  width: 100%
}

@media all and (max-width: 736px) {
  .hero {
    height: 60rem;
  }

  .headingBottom { 
    display: none;
  }
  .pageContent {
    width: 90%;
  }

  .topBarContent {
    width: 90%;
  }

  .graphImage {
    height: 10rem;
  }

  .headingYritystieto {
    font-size: 8rem;
  }

  .headingBusinessTampere {
    font-size: 4rem;
  }
}
