.info {
  display: flex;
  flex-direction: row;
  padding: 1rem;
}

.info td {
  padding: 1.25rem;
}

.info .left,
.info .right {
  width: 50%;
}

.info tr th {
  font-weight: bold;
  width: 15rem;
}

.info tr:nth-child(odd) th {
  background: rgba(0, 65, 125, 0.1);
}

.info a,
.info a:link,
.info a:hover,
.info a:visited {
  color: #111c43;
  text-decoration: underline;
}

.info a:focus {
  outline: auto;
}
.info tr {
  flex-direction: row;
}

.info tr td:last-child {
  white-space: normal;
  word-wrap: break-word;
  resize: both;
  box-sizing: border-box;
  max-width: 30vw;
}

.title {
  display: flex;
  margin: 1rem 0;
}

.company {
  color: #212529;
  font-weight: 900;
  font-size: 3rem;
  font-family: "Montserrat";
  padding: 0 1rem;
  text-transform: uppercase;
  margin: 0;
}

.link {
  color: #d15371;
}

.branch {
  color: #212529;
  font-weight: 900;
  font-size: 3rem;
  font-family: "Montserrat";
  padding: 0 1rem;
  text-transform: uppercase;
}

.secondary {
  color: #212529;
  opacity: 0.5;
  padding: 0 1rem;
  font-style: italic;
}

.section {
  display: flex;
  flex-direction: column;
}


@media all and (max-width: 736px) {
  .info {
    flex-direction: column;
    padding: 2rem;
  }

  .info .left,
  .info .right {
    width: 100%;
  }

  .info tr td:first-child {
    font-weight: bold;
    min-width: 12rem;
    overflow-wrap: unset;
  }

  .info tr td:last-child {
    max-width: 50vw;
  }
}

@media all and (max-width: 480px) {
  .info {
    padding: 0.5rem 0;
  }
}
