.button {
  border: 1px solid #28487f;
  border-radius: 2px;
  font-family: Chivo;
  font-size: 1.7rem;
  color: white;
  background-color: #28487f;
  padding: 1.3rem 5rem;
  white-space: nowrap;
  max-width: 320px;
  cursor: pointer;
  transition: 150ms all;
  text-decoration: none;
}

.button:focus {
  text-decoration: underline;
}