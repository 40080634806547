.revenue-widget {
  position: relative;
  justify-content: center;
}

.revenue-widget .content {
  min-height: 68rem;
}

.revenue-container {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
}

.revenue-widget .loading-container {
  height: 50rem;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: fadein 0.5s;
}

.revenue-info {
  position: absolute;
  top: -5rem; 
  right: 2rem;
}

.dropdown {
  background: #F7F7F7;
  border: 1px solid #E1E1E1;
  box-sizing: border-box;
  border-radius: 3px;
  color: #343434;
  font-family: Chivo;
  font-style: normal;
  text-align: left;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1.5rem;
  margin: 2rem 2rem;
  margin-bottom: 0;
}

.button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 3rem;
  margin-top: 1.2rem;
}

.revenue-list-wrapper {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
}
