.personnel-selector-wrapper .modal {
  position: absolute;
  top: -11rem;
  left: -50%;
}

.personnel-selector-wrapper label {
  display: flex;
  flex-direction: column;
}

.personnel-selector-wrapper input {
  font-size: 2rem;
  padding: 0.5rem;
  margin: 1rem 0;
  border-radius: 0.3rem;
  width: 100%;
  border: 1px solid rgba(0, 65, 125, 0.5);
}
