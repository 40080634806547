.container {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.turnoverContainer {
  background-color: #f7f7f7;
  padding: 1rem;
  display: flex;
  flex: 1;
  margin: 0.5px;
  flex-direction: column;
}

.turnover {
  display: flex;
  flex: 0 1 auto;
  flex-flow: row wrap;
  justify-content: space-evenly;
}

.square {
  height: 2rem;
  width: 2rem;
  margin-right: 1rem;
}

.turnoverWrapper {
  display: flex;
  flex: 1 1 33%;
  white-space: nowrap;
  align-items: center;
  font-size: 1.6rem;
  margin: 1.25rem 1.5rem;
}

.chartButton {
  margin-top: 2rem;
}