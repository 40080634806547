.container {
  display: flex;
  flex-direction: column;
  flex: 0.2;
  padding: 0 2rem;
  max-width: 500px;
  min-width: 300px;
}

.header {
  font-family: Montserrat;
  font-weight: bold;
  font-size: 28px;
  text-align: left;
  color: #d15371;
  margin-bottom: 2rem;
}

.filterHeader {
  font-family: Chivo;
  font-weight: bold;
  font-size: 22px;
  color: #212529;
  margin-bottom: 0.4rem;
  margin-top: 2rem;
}

.filterOptions {
  list-style: none;
  padding-left: 0;
}

.filter {
  margin-bottom: 1rem;
}

.selectedIndustries {
  display: flex;
  flex-flow: row wrap;
  margin-bottom: 5rem;
}

.selectedIndustry {
  background: #d15371;
  border-radius: 5px;
  padding: 1rem;
  padding-right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0;
  margin: 1rem 1rem 0 0;
}

.selectedIndustry span {
  font-family: "Chivo";
  font-size: 1.4rem;
  color: white;
  color: white;
  max-width: 24rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.closeButton {
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.industryHeader {
  margin-bottom: 2rem;
}