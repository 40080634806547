.pageContent {
  flex: 1 0 auto;
  padding: 0;
  padding-bottom: 5rem;
}

.stickyCheck {
  min-height: 1px;
  min-width: 1px;
}
