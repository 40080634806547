.companyScreen {
  position: relative;
  min-height: 30rem;
}

.companyPage {
  margin-top: 1rem;
  animation: fadein 0.5s;
}

.companyPage table {
  font-size: 2rem;
  border-collapse: collapse;
}

/* .companyPage td{
  padding: 1.25rem 2.75rem; 
}  */

.notFound {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 4.8rem;
  padding: 5rem 0;
}

.backButton > * {
  cursor: pointer;
  color: #345ca3;
  font-size: 1.8rem;
  text-decoration: underline;
  background: transparent;
  border: none;
}
.backButton > *:focus {
  outline: auto;
}

.pageContent {
  flex: 1 0 auto;
  margin: 1rem auto 3rem;
  width: 80%;
}

.loadingContainer {
  position: relative;
  height: 100vh;
}

.companyScreen .backButton {
  padding: 0rem 2rem 1rem;
  cursor: pointer;
}

.tabList {
  border-bottom: none;
  font-family: Chivo;
  text-transform: uppercase;
  font-weight: 700;
  justify-content: center;
  background-color: white;
  border: 1px solid #e1e1e1;
  box-sizing: border-box;
  border-radius: 3px;
  margin: 3rem 2rem;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: row;
  padding: 2rem;
  list-style: none;
}
.selectedTab:focus {
  border-color: transparent;
  box-shadow: none;
  border-bottom: 2px solid #d15371;
}
.selectedTab:focus:after {
  content: none;
}

.selectedTab {
  background: transparent;
  border-bottom: 2px solid #d15371;
}

.tab {
  cursor: pointer;
  padding: 0.8rem;
  margin: 0 2rem;
}

@media all and (max-width: 736px) {
  .pageContent {
    width: 90%;
  }

  .companyScreen .backButton {
    padding: 2rem 4rem 1rem;
  }

  .tabList {
    margin: 3rem 1rem;
  }
}
