.content {
  margin: 0 auto;
  width: 80%;
}

.col {
  padding: 0 0.8rem;
}

@media all and (max-width: 736px) {
  .content {
    width: 90%;
  }
}