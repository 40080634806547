/* Customize the label (the container) */
.container {
  display: flex;
  position: relative;
  cursor: pointer;
  padding-left: 4rem;
  font-size: 1.8rem;
  user-select: none;
  color: #212529;
  font-family: Chivo;
}

/* Hide the browser's default checkbox */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 16px;
  width: 16px;
  background-color: #ffffff;
  border: 1px solid #e1e1e1;
  border-radius: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dot {
  max-height: 8px;
  max-width: 8px;
  background-color: #333;
  display: block;
  border-radius: 8px;
  height: 100%;
  width: 100%;
}

.colorBox {
  width: 1.8rem;
  height: 1.8rem;
  border-radius: 50%;
  right: 6.66rem;
  bottom: 2px;
  position: absolute;
}

.container input:focus + .checkmark {
  border:1px solid #345ca3;
}