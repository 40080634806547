.chartLegend {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  min-width: 50%;
  padding: 50%;
  background-color: rgba(224, 224, 224, 0.4);
  padding: 1.5rem 2rem;
  flex-wrap: wrap;
  border: 1px solid rgba(225, 225, 225, 0.5);
}

.square {
  max-height: 2rem;
  min-width: 2rem;
  margin-right: 1rem;
}

.legendName {
  font-family: Chivo;
  font-weight: 700;
  font-size: 1.5rem;
}

.legendWrapper {
  display: flex;
  flex-direction: row;
  font-size: 1.3rem;
  margin: 0.5rem 1rem;
}
