.card {
  border: 1px solid #E1E1E1;
  box-sizing: border-box;
  min-height: 55rem;
  display: flex;
  background-color: #FFFFFF;
  flex-direction: column;
  align-items: center;
  margin-top: 1.5rem;
  flex: 1 1 auto;
  transition: 150ms all;
}

.headingWrapper {
  display: flex;
  padding: 1rem 5rem;
  align-items: baseline;
  justify-content: center;
}

.heading {
  font-family: Montserrat;
  text-transform: uppercase;
  font-size: 2.4rem;
  margin-bottom: 0;
  text-align: center;
}

.content {
  width: 100%;
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  position: relative;
}