body {
  margin: 0;
  padding: 0;
  font-family: "Chivo", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #212529;
  background: rgb(241, 241, 241);
}

#root {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

* {
  outline-color: #345ca3;
}

html {
  font-size: 5.10416px;
  -webkit-text-size-adjust: none;
}

@media all and (min-width: 480px) and (max-width: 736px) {
  html {
    font-size: 5.10416px;
  }
}
@media all and (min-width: 737px) and (max-width: 979px) {
  html {
    font-size: 5.10416px;
  }
}
@media all and (min-width: 980px) and (max-width: 1279px) {
  html {
    font-size: 6.66672px;
  }
}
@media all and (min-width: 1280px) {
  html {
    font-size: 8.802px;
  }
}

body {
  font-size: 16px;
  font-size: 1.6rem;
}

tspan {
  font-family: Chivo !important;
}

a:focus,
a:hover,
a:active {
  outline: none;
  text-decoration: underline;
}
.Dropdown-root {
  max-width: 100%;
}

.chevron {
  width: 1.8rem;
  /* padding-bottom: 0.2rem; */
  padding-top: 0.5rem;
}

.visible {
  opacity: 1;
  visibility: visible;
}

.invisible {
  opacity: 0;
  visibility: hidden;
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeout {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@-moz-keyframes fadeout {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@-webkit-keyframes fadeout {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@-o-keyframes fadeout {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

input {
  border-radius: 0;
  -webkit-border-radius: 0;
  -webkit-appearance: none;
}

.skip-to-content {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  white-space: nowrap;
  border: 0;
}

.skip-to-content:focus {
  font-weight: 700;
  font-size: 2rem;
  z-index: 100000;
  top: 1.5rem;
  left: 1.5rem;
  display: block;
  overflow: visible;
  clip: auto;
  width: auto;
  height: auto;
  margin: 0;
  padding: 0.75rem;
  white-space: normal;
  text-decoration: none;
  color: #ae416a;
  background: #fff;
  box-shadow: 0 0 2px 2px hsla(0, 0%, 5%, 0.3);
}
