.registry {
  padding: 1rem;
}

.registry table {
  width: 100%;
}

.registry td {
  padding: 0rem 1rem;
}

.registry tr:nth-child(odd) {
  background: rgba(0, 65, 125, 0.1);
}

.registry tr td:first-child {
  font-weight: bold;
  margin-left: 1.5rem;
}

.registry tr td:nth-child(2) p {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.registry tr td p img {
  margin-right: 1rem;
  padding: 0rem
}

.registry tr td:last-child {
  text-align: right;
}

.indicator {
  width: 3rem;
}

@media all and (max-width: 736px) {
  .registry {
    padding: 2rem;
  }

  .indicator {
    width: 2rem;
  }
}

@media all and (max-width: 480px) {
  .registry {
    padding: 0.5rem 0;
  }

  .inRegistry {
    display: none;
  }

  .indicator {
    width: 1.5rem;
  }
}
