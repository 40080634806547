.container {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.companyTypesWrapper {
  background-color: #F7F7F7;
  padding: 1rem;
  display: flex;
  flex: 1;
  margin: 0.5px;
  flex-direction: column;
}

.companyTypes {
  display: flex;
  flex: 0 1 auto;
  flex-flow: row wrap;
  justify-content: space-evenly;
}

.square {
  height: 2rem;
  min-width: 2rem;
  margin-right: 1rem;
}

.companyWrapper {
  display: flex;
  flex: 1 1 33%;
  align-items: center;
  font-size: 1.6rem;
  margin: 1.25rem 1.5rem;
}

.companyTypesWrapper button {
  margin: auto;
}