.content {
  margin: 0 auto;
  width: 80%;
}

.col {
  padding-left: 0.8rem;
  padding-right: 0.8rem;
  display: flex;
  flex-direction: column;
}

.cardContent {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
}

@media all and (max-width: 736px) {
  .content {
    width: 90%;
  }
}