.selector-wrapper {
  /* z-index: 999; */
  position: relative;
  width: 100%;
}

.selector-button {
  height: 4.5rem;
  border: none;
  background: #345ca3;
  border-radius: 1000px;
  cursor: pointer;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border: 1px solid transparent;
  width: 100%;
  margin-right: 1rem;
}

.selector-button:focus {
  border-color: #fff;
  outline: none;
}
.selector-button.true {
  z-index: 999;
}

.button-text {
  color: white;
  font-size: 1.8rem;
  font-weight: 700;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 1rem;
  margin-left: 0.5rem;
}

.amount {
  background: #7a65a7;
  color: white;
  width: 1.5rem;
  padding: 0.5rem;
  margin: 0 0.5rem;
  border-radius: 0.3rem;
  font-weight: bold;
  text-align: center;
  font-size: 1.6rem;
}

.amount.none {
  opacity: 0;
}

.amount.some {
  opacity: 1;
}

.selector-wrapper .chevron {
  width: 1.4rem;
  padding-right: 0.5rem;
  margin-right: 0.5rem;
}

.selector-button.true::before {
  content: "";
  position: absolute;
  z-index: 995;
  top: 9rem;
  left: 5rem;
  width: 10rem;
  height: 10rem;
  background: white;
  transform: rotate(45deg);
  border-radius: 0.3rem;
}

.selector-wrapper .modal-wrapper {
  position: absolute;
  top: 19rem;
  left: 0;
  right: 0;
  z-index: 999;
  width: 100%;
}

.selector-wrapper .modal {
  background: white;
  border-radius: 0.3rem;
  padding: 3rem;
  display: flex;
  flex: 1;
  flex-direction: column;
}

.selector-wrapper ul {
  display: flex;
  flex-flow: column wrap;
  height: 100%;
  list-style: none;
  padding: 0;
  max-height: 55rem;
}

.selector-wrapper ul li {
  padding: 0.5em 0;
  white-space: pre-wrap;
  flex: 1 1 auto;
}

.selector-wrapper ul li span {
  font-size: 1.8rem;
  text-decoration: none;
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.selector-wrapper ul li span input {
  cursor: pointer;
}

.selector-wrapper label {
  width: 40rem;
  text-overflow: ellipsis;
}

.selector-wrapper ul li input {
  margin-right: 1.5rem;
}

.selector-wrapper .bottom {
  display: flex;
  flex-direction: row;
  flex: 1 1 auto;
}

.confirm-button {
  background: #7a65a7;
  border: none;
  width: 20rem;
  height: 5rem;
  color: white;
  font-size: 1.8rem;
  font-weight: bold;
  cursor: pointer;
  width: 100%;
}

.select-all-button {
  background: transparent;
  border: none;
  width: 20rem;
  height: 5rem;
  color: #7a65a7;
  font-size: 1.8rem;
  font-weight: bold;
  cursor: pointer;
}

.cancel-button {
  background: transparent;
  border: none;
  width: 20rem;
  height: 5rem;
  color: #d15371;
  font-size: 1.8rem;
  font-weight: bold;
  cursor: pointer;
}

/* Customize the label (the container) */
.check-container {
  display: flex;
  position: relative;
  cursor: pointer;
  padding-left: 4rem;
  font-size: 1.8rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.check-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 2rem;
  width: 2rem;
  background-color: #fbfdff;
  border: 1px solid rgba(0, 65, 125, 0.5);
  border-radius: 0.3rem;
}

/* On mouse-over, add a grey background color */
.check-container:hover input ~ .checkmark {
  background-color: #eee;
  transition: all 0.2s;
}

/* When the checkbox is checked, add a blue background */
.check-container input:checked ~ .checkmark {
  transition: all 0.2s;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.check-container input:checked ~ .checkmark:after {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Style the checkmark/indicator */
.check-container .checkmark:after {
  left: 33%;
  top: 10%;
  width: 0.5rem;
  height: 1rem;
  border: solid #7a65a7;
  border-width: 0 0.25rem 0.25rem 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

@media all and (max-width: 1050px) {
  .selector-button {
    padding: 0 0.5rem;
  }

  .selector-button .chevron {
    width: 1.5rem;
  }
}

@media all and (max-width: 736px) {
  .selector-button {
    padding: 0 0.5rem;
  }
}
