.footerWrapper {
  height: 40rem;
  display: flex;
  flex: 1 0 auto;
  justify-content: center;
  align-items: flex-start;
  background-color: #28487f;
  color: white;
  background-position: bottom;
  background-size: cover;
}

.footerContainer {
  width: 85%;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  padding-top: 5rem;
}

/* 
Kysy tästä
p,
a,
strong,
span {
  margin: 0;
  padding: 0;
  text-decoration: none;
} */

.ContactInfoLink:link,
.ContactInfoLink:visited,
.ContactInfoLink:hover,
.ContactInfoLink:active {
  color: white;
}

.ContactInfoLink:focus {
  outline: auto;
}

.logo {
  width: 20rem;
}

.links {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.link {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20rem;
  height: 5rem;
  border: 0.2rem solid white;
  margin: 0.5rem;
  text-decoration: none;
  font-weight: bold;
  color: white;
}

.footerSection {
  width: 30vw;
  padding: 0 1rem;
}

.copyright {
  font-size: 1.4rem;
  margin-top: 4rem;
}

.ContactInfoLine {
  margin: 1rem 0;
}

.businessInfo {
  margin-bottom: 2rem;
}

.businessInfoLine {
  margin: 0;
}

@media all and (max-width: 480px) {
  .footerWrapper {
    height: auto;
  }
  
  .footerContainer {
    width: 100%;
    flex-direction: column;
    padding: 3rem;
  }

  .footerSection {
    width: 100%;
  }

  .contactInfo {
    padding-top: 2rem;
  }

  .links {
    align-items: flex-start;
  }
}
