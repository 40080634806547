.container {
  width: 75%;
  margin: 3rem auto 5rem;
  display: flex;
  flex-direction: column;
  font-size: 120%;
}

.container h1,
.container h2,
.container h3,
.container h4 {
  font-family: "Montserrat";
  color: #00417d;
}

.container a,
.container a:link,
.container a:active,
.container a:visited,
.container a:hover {
  color: #00417d;
  text-decoration: underline;
}

@media all and (max-width: 736px) {
  .container {
    width: 90%;
  }
}
