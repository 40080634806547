.card {
  border: 1px solid #E1E1E1;
  min-height: 8rem;
  display: flex;
  justify-content: center;
  background: #E0E0E0;
  position: sticky;
  top: 0;
  z-index: 99;
  margin: 1.5rem auto;
  width: calc(80vw - 2rem);
  transition: 200ms all;
}

.card.sticky {
  width: 100vw;
}

.filtersContainer {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: calc(80vw - 2rem);
}

.regionWrapper {
  display: flex;
  align-items: center;
  margin-left: 0;
  justify-content: flex-start
}

.industryWrapper {
  display: flex;
  align-items: center;
}

.yearWrapper {
  display: flex;
  align-items: center;
} 

.dropdown.dropdownFilter {
  height: 06rem;
  font-size: 3.5rem;
  font-family: Montserrat;
  font-weight: bold;
  border: 0;
  padding: 1.2rem 2rem 0 2rem;
  text-align: left;
  display: flex;
  flex-direction: row;
  background: transparent;
  align-items: center;
  margin-top: 0;
  margin-bottom: 1.7rem;
}

.dropdown:hover {
  box-shadow: none !important;
}

.placeholderClassName {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 1.5rem;
  
}

.left {
  display: flex;  
}

@media all and (max-width: 736px) {
  .card {
    margin-top: 3rem;
    width: calc(90vw - 2rem);
  }
}
