.metricCard {
  border: 1px solid #e1e1e1;
  padding: 2rem;
  display: flex;
  background-color: #ffffff;
  flex-direction: column;
  align-items: center;
  margin-top: 1.5rem;
}

.headingWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: 4rem;
}

.multipleMetricsContainer {
  display: flex;
}

.multipleMetricsContainer > * {
  padding-right: 4rem;
}

.multipleMetricsContainer > *:last-of-type {
  padding-right: 0;
}

.metricHeading {
  font-family: Montserrat;
  text-transform: uppercase;
  font-size: 1.7rem;
  margin-bottom: 0;
  display: flex;
  margin-top: 0;
}

.metricContent {
  font-family: Montserrat;
  font-size: 6.4rem;
  font-weight: 700;
  color: #d15371;
  display: flex;
}

a.metricContent:focus {
  outline: auto;
}

.metricSubContent {
  font-family: Chivo;
  font-style: normal;
  color: #707575;
}

.multipleMetrics {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
}

.multipleMetricsContainer > div {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.headingWithModal {
  display: flex;
  align-items: center;
  position: relative;
}

.absoluteModal {
  position: absolute;
  right: -3.5rem;
  bottom: 0;
}
