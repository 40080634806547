.founded-selector-wrapper .modal {
  position: absolute;
  top: -11rem;
}

.founded-selector-wrapper label {
  display: flex;
  flex-direction: column;
}

.founded-selector-wrapper input {
  font-size: 2rem;
  padding: 0.5rem 0;
  text-indent: 0.5rem;
  margin: 1rem 0;
  border-radius: 3px;
  width: 100%;
  border: 1px solid rgba(0, 65, 125, 0.5);
}
