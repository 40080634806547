.finances {
  padding: 1rem;
}

.row {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  flex: 1;
  flex-wrap: nowrap;
}

/* 
.header {
  padding-top: 3rem;
} */

.row:last-child {
  border-top: 1px solid #ddd;
}

.cell {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-right: 1px solid #ddd;
  text-align: center;
  flex: 1;
}

.cell:last-child {
  border: none;
}

.huge {
  font-size: 4.8rem;
  font-weight: 600;
  margin-top: 0.25rem;
  margin-bottom: 1rem;
}

.dropdown {
  font-size: 3rem;
  font-family: Montserrat;
  font-weight: bold;
  border: 0;
  display: flex;
  background: transparent;
  align-items: center;
  border-bottom: 2px solid #d15371;
  padding-right: 1rem;
}

.dropdown:hover {
  box-shadow: none !important;
}

.title {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

.polygon {
  width: 1rem;
  position: relative;
  bottom: 0.666rem;
  left: 0.666rem;
}

@media all and (max-width: 736px) {
  .finances {
    padding: 4rem;
  }

  .cell {
    padding: 1rem;
  }

  .huge {
    font-size: 5rem;
  }
}

@media all and (max-width: 480px) {
  .finances {
    padding: 2.5rem;
  }

  .cell {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 8rem;
    align-items: center;
  }

  .huge {
    font-size: 2.4rem;
  }
}
