.mapWrapper {
  width: 100%;
  height: 100%;
  border: 1px solid #E1E1E1;
}

.popup {
}

.popupInfo {
}

.companyName,
.companyName:visited,
.companyName:focus {
  color: #212529;
  font-family: Chivo;
  font-weight: 600;
  padding: 2rem;
}

.marker {
  height: 4rem;
  width: 1rem;
  aspect-ratio: 1;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
}