.wrapper {
  position: relative;
  background-color: #28487f;
  padding: 4rem 5rem 4rem;
  border-radius: 0 10px 10px;
  margin: -20rem -10px 0;
  z-index: 995;
}

.logoContainer {
  background-color: #28487f;
  position: absolute;
  bottom: 100%;
  left: 0;
  height: 5rem;
  padding: 2.8rem 3.5rem;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.logoContainer:after {
  content: "";
  display: block;
  height: 84%;
  width: 10px;
  position: absolute;
  left: calc(100% - 10px);
  bottom: -10px;
  border: solid #28487f;
  border-color: #28487f;
  border-width: 0 0 10px 10px;
  border-bottom-left-radius: 20px;
}

.searchLogo {
  height: 100%;
}

.search {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: top;
  height: 9rem;
  position: relative;
  background-color: white;
  border-radius: 0.75rem;
  padding: 1rem;
}

.input {
  left: 0rem;
  padding: 1rem 3rem;
  width: 100%;
  border: none;
  font-size: 2rem;
  border-radius: 0.75rem;
}

.input::placeholder {
  color: #707575;
}

.iconButton {
  position: relative;
  top: 2.5rem;
  right: -1px;
  height: 4rem;
  width: 8rem;
  border: none;
  color: #ae416a;
  background: transparent;
  cursor: pointer;
  z-index: 9;
}

.searchButtonWrapper {
  position: absolute;
  top: 1rem;
  right: 1rem;
  bottom: 1rem;
  z-index: 9;
}

.controls {
  display: flex;
  margin-top: 1.5rem;
  justify-content: flex-end;
}

.searchButton {
  height: 6rem; /* 50px; */
  border: none;
  color: #fff;
  background: #d15371;
  border-radius: 0.75rem;
  font-size: 2rem;
  border-radius: 50px;
  font-weight: 700;
  cursor: pointer;
  transition: all 250ms;
  width: 100%;
  max-width: 150px;
  border: 1px solid transparent;
}

.clearAll {
  border: none;
  height: 6rem;
  color: white;
  font-size: 1.8rem;
  cursor: pointer;
  background: #345ca3;
  border: 1px solid transparent;
  border-radius: 1000px;
  font-weight: 700;
  font-family: Chivo;
  padding-left: 2.5rem;
  padding-right: 2.5rem;
  margin-left: 1rem;
  width: 100%;
  max-width: 150px;
  outline: none;
}
.clearAll:focus {
  border-color: #fff;
}

.searchButton:hover,
.searchButton:focus {
  outline: none;
  border-color: #fff;
}

.iconButton:disabled {
  background: white;
}

.button {
  padding: 0;
  border: none;
  outline: none;
  font: inherit;
  color: inherit;
  background: none;
}

.overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 10;
}

.filtersWrapper {
  display: flex;
  flex-direction: row;
  margin-top: 1.5rem;
}

.filters {
  display: flex;
  flex: 1 1 auto;
  justify-content: space-between;
  flex-wrap: wrap;
  position: relative;
}

.filters > *:not(:last-of-type) {
  /* width: calc(100% / 6 - 1rem); */
  margin-right: .825rem;
}

.filters > * {
  flex: 1;
  margin-bottom: 2rem;
  max-width: calc(100% / 6.5 - 1rem);
}

.filters .text {
  color: white;
  font-size: 1.8rem;
  margin: 0 1rem;
}

@media all and (max-width: 736px) {
  .filtersWrapper,
  .overlay {
    display: none;
  }

  .clearAll {
    display: none;
    padding-top: 1rem;
  }

  .search {
    height: 7rem;
  }

  .iconButton {
    width: 10rem;
    font-size: 2rem;
    top: 1.5rem;
    right: 0;
  }

  .input {
    font-size: 2rem;
    text-indent: 2rem;
  }

  .input::placeholder {
    font-size: 1.7rem;
  }
}
