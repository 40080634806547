.button {
  border: 1px solid #28487f;
  border-radius: 2px;
  font-family: Chivo;
  font-size: 1.7rem;
  color: white;
  background-color: #28487f;
  padding: 1.3rem 5rem;
  white-space: nowrap;
  max-width: 320px;
  cursor: pointer;
  transition: 150ms all;
}

.noFill {
  border: 1px solid #28487f;
  border-radius: 2px;
  background-color: white;
  font-family: Chivo;
  font-size: 1.7rem;
  color: #28487f;
  padding: 1.3rem 4rem;
  text-decoration-line: none;
  white-space: nowrap;
  max-width: 320px;
  cursor: pointer;
  transition: 150ms all;
}

.disabled {
  opacity: 0.666;
  cursor: not-allowed;
}

.button:focus {
  text-decoration: underline;
}

.button:hover {
  opacity: 0.7;
}

.noFill:hover {
  background-color: #EEEEEE;
}