.wrapper {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 2rem;
}

.fundingContainer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  flex: 1;
}

.heading {
  font-family: Chivo;
  font-style: normal;
  font-weight: bold;
  font-size: 1.8rem;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 0;
  margin-top: 3rem;
}
.secondYearWrapper {
  width: 85%;
  border-bottom: 1px solid #e7e7e7;
}

.funding {
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 4.8rem;
  text-transform: uppercase;
  margin: 0;
  margin-top: 1rem;
  margin-bottom: 1rem;
  color: #d15371;
}

.selectContainer {
  padding: 2.5rem;
}

.buttonWrapper {
  display: flex;
  justify-content: center;
  margin: 2rem;
}

.buttonWrapper a {
  display: flex;
  padding: 1.3rem;
  justify-content: center;
}

.dropdown {
  background: #f7f7f7;
  border: 1px solid #e1e1e1;
  box-sizing: border-box;
  border-radius: 3px;
  color: #343434;
  font-family: Chivo;
  font-style: normal;
  text-align: left;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1.5rem;
}
