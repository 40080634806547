.infoModal {
  position: relative;
  white-space: pre-wrap;
}

.modal-left {
  padding: 2rem;
  position: absolute;
  width: 26.66rem;
  background: #28487f;
  color: white;
  border-radius: 0.3rem;
  font-size: 1.8rem;
  transition: all 0.15s;
  box-shadow: 2px 3px 2px rgba(0, 0, 0, 0.33);
  z-index: 10;
  right: 100%;
}

.borderlessButton {
  border: none;
  background: transparent;
  border-radius: 3rem;
  cursor: pointer;
  outline: none;
}
.borderlessButton:focus img {
  border-color: #28487f;
}
.borderlessButton img {
  width: 2rem;
  height: 2rem;
  border: 1px solid transparent;
}
.modal-right {
  padding: 2rem;
  position: absolute;
  width: 26.66rem;
  background: #00417d;
  color: white;
  border-radius: 0.3rem;
  font-size: 1.8rem;
  transition: all 0.15s;
  box-shadow: 2px 3px 2px rgba(0, 0, 0, 0.33);
  z-index: 10;
  left: 100%;
}

.modal p {
  text-align: left;
}

@media all and (max-width: 480px) {
  .modal.right {
    left: 175%;
  }

  .modal.left {
    right: 175%;
  }

  .modal.right:before {
    bottom: 8rem;
  }

  .modal.left:before {
    bottom: 8rem;
  }
}
