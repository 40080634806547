/* Customize the label (the container) */
.container {
  display: flex;
  position: relative;
  cursor: pointer;
  padding-left: 4rem;
  font-size: 1.8rem;
  user-select: none;
  color: #212529;
  font-family: Chivo;
}

/* Hide the browser's default checkbox */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 2rem;
  width: 2rem;
  background-color: #f7f7f7;
  border: 1px solid #e1e1e1;
  border-radius: 3px;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  background-color: #eee;
  transition: all 0.2s;
}

/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
  transition: all 0.2s;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
  left: 33%;
  top: 10%;
  width: 0.5rem;
  height: 1rem;
  border: solid #35a501;
  border-width: 0 0.25rem 0.25rem 0;
  transform: rotate(45deg);
}

.container input:focus + .checkmark {
  border:1px solid #345ca3;
}
.colorBox {
  width: 1.8rem;
  height: 1.8rem;
  border-radius: 50%;
  right: 6.66rem;
  bottom: 2px;
  position: absolute;
}
