.top500container {
  flex: 1 0 auto;
  margin: 1rem auto 3rem;
  width: calc(75% + (5rem));
  min-height: 100vh;
}

.listContainer {
  margin: 3rem 0;
  border: 1px solid rgba(225, 225, 225, 0.5);
  border-radius: 3px;
  background-color: white;
  min-height: 100vh;
  position: relative;
}

.backButton > * {
  cursor: pointer;
  color: #00417d;
  font-size: 1.8rem;
  text-decoration: underline;
  background: transparent;
  border: none;
  margin-left: 3rem;
}

.loadingContainer {
  position: absolute;
  top: 6.66rem;
  left: 0;
  right: 0;
}

@media all and (max-width: 736px) {
  .top500container {
    width: 90%;
  }
}
