
.container {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.industryContainer {
  background-color: #F7F7F7;
  justify-content: space-between;
  display: flex;
  flex: 1;
  margin: 0.5px;
}

.industryList {
  column-count: 2;
  column-gap: 3rem;
  margin: 0;
  height: fit-content;
  padding: 2rem 3rem;
}

.industryWrapper {
  font-family: Chivo;
  font-size: 1.6rem;
  break-inside: avoid;
  margin-bottom: 1rem;
  margin-left: 1rem;
}
