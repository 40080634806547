.closeButton {
  margin: auto;
}

.controls {
  display: flex;
  justify-content: center;
  padding-top: 2rem;
}

.wrapper {
  display: flex;
  margin: 2rem;
  justify-content: center;
}

.trigger {
  width: 100%;
}

.textContent {
  margin: 3rem 0;
}