.ribbon-container {
  border: 1px solid #e1e1e1;
  margin: 2rem;
  background: white;
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  padding: 2rem 4rem;
}

.ribbon-container .header {
  display: flex;
  flex-direction: row;
}

.ribbon-container .header h1 {
  color: #212529;
  font-weight: 900;
  font-size: 3rem;
  font-family: "Montserrat";
  padding: 0 1rem;
  text-transform: uppercase;
}

@media all and (max-width: 480px) {
  .ribbon-container {
    margin: 2rem 1rem;
    padding: 1.5rem 2.5rem;
  }
}
