
.notAvailableWrapper {
  height: 40.5rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.notAvailable {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Chivo;
  color: #d15371;
  font-size: 2rem;
  font-weight: 700;
  max-width: 21rem;
  text-align: center;
}